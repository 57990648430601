.CountryFlag {
	height: 1.5em;
	width: 1.5em;
	margin: 0 0.5em;
	vertical-align: -0.3em;
}

.CountryFlagPlaceholder {
	display: inline-block;
}

.CountryFlagText {
	margin: 0 0.25em;
}

.CountryFlag:first-child {
	margin-left: 0;
}
