.HeaderBackground {
	background: -webkit-linear-gradient(to bottom, #000, transparent);
	background: linear-gradient(to bottom, #000, transparent);
	padding-bottom: 16rem;
}

.HeaderBackground + * {
	margin-top: -14rem;
	margin-bottom: 2rem;
}
