html {
	min-height: 100%;
	background-color: #303030;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img.emoji {
	height: 1.2em;
	width: 1.2em;
	vertical-align: -0.3em;
}

h2 + .MuiCard-root {
	margin-top: 0.5em;
}

.Spacer5 { margin-bottom: 0.5em }
.Spacer10 { margin-bottom: 1em }
.Spacer15 { margin-bottom: 1.5em }
.Spacer20 { margin-bottom: 2em }
