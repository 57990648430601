
.KnockoutBracket {
	display: grid;
	white-space: nowrap;
	overflow-x: auto;
}

.KnockoutBracket a {
	color: rgba(255, 255, 255, 0.87);
	text-decoration: none;
}

.KnockoutBracket .KnockoutBracketPosition {
	display: grid;
	grid-template-rows: repeat(4, 1fr);
	grid-auto-flow: column;
}

.KnockoutBracket .KnockoutBracketDensePosition.KnockoutBracketRound1 {
	grid-template-rows: 0.2fr 1fr 1fr 0.2fr;
}

.KnockoutBracket .KnockoutBracketTeamPosition {
	display: flex;
	flex-direction: column;
	border-left: 2px solid #000;
}

.KnockoutBracket .KnockoutBracketRound1 .KnockoutBracketTeamPosition {
	border-left: 0;
}

.KnockoutBracket .KnockoutBracketTeam {
	padding-left: 0.5em;
	padding-right: 1em;
}

.KnockoutBracket .KnockoutBracketPosition:hover .KnockoutBracketTeam {
	background-color: rgba(255, 255, 255, 0.08);
}

.KnockoutBracket .KnockoutBracketTopTeam {
	justify-content: flex-end;
	border-bottom: 1px solid #000;
}

.KnockoutBracket .KnockoutBracketBottomTeam {
	justify-content: flex-start;
	border-top: 1px solid #000;
}
