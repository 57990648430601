
.CardButton {
	text-align: center;
	padding: 0.5rem;
	line-height: 1;
}

.CardButtonActionArea {
	transition: 0.2s;
}

.CardButtonActionArea:hover {
	transform: scale(1.1);
}

.CardButtonPrimaryText {
	font-weight: bold;
	font-size: 2rem;
}

.CardButtonSecondaryText {
	padding-top: 0.5rem;
}
