
.MuiAvatar-root.AvatarWithNoBackground {
	background: none;
	border-radius: 0;
}

.AvatarImageContain .MuiAvatar-img {
	object-fit: contain;
}

.TournamentList .MuiListItemSecondaryAction-root {
	right: 0;
}

@media (max-width: 600px) {
	.TournamentList .MuiListItemSecondaryAction-root {
		display: none;
	}
}

@media (max-width: 700px) {
	.TournamentList .MuiButton-root {
		min-width: auto;
	}
}

@media (min-width: 700px) and (max-width: 960px) {
	.TournamentList .MuiButton-root {
		min-width: 50px;
	}
}
